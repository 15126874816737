import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import RestApi from '../views/CallRestApi.vue'
import HomeView from '../views/HomeView.vue'
import FullStack from '../views/FullStackView.vue'
import Hour1 from '../views/1HourView.vue'
import Minute30 from '../views/30MinuteView.vue'
import KewlTube from '../views/KewlTubeView.vue'
import BrainDrops from '../views/BrainDropsView.vue'
import Resume from '../views/ResumeView.vue'
import Dashboard from '../views/DashboardView.vue'
import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/callrestapi',
    name: 'REST Api page',
    component: RestApi
  },
  {
    path: '/home',
    name: 'MainPage',
    component: Home
  },
  {
    path: '/resume',
    name: 'Resume',
    component: Resume
  },
  {
    path: '/kewltube',
    name: 'KewlTube',
    component: KewlTube
  },
  {
    path: '/fullstack',
    name: 'FullStack',
    component: FullStack
  },
  {
    path: '/1hour',
    name: '1Hour',
    component: Hour1
  },
  {
    path: '/30minute',
    name: '30Minute',
    component: Minute30
  },
  {
    path: '/braindrops',
    name: 'BrainDrops',
    component: BrainDrops
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
