<template>
    <div class="container">
      <table>
        <tr>
          <td>
            <p>I never get tired of this version of Lorde's Royals.</p>
            <p>
              <iframe
                width="420"
                height="236"
                src="//www.youtube.com/embed/VBmCJEehYtU"
                frameborder="0"
                allowfullscreen=""
              ></iframe>
            </p>
          </td>
          <td>
            <p>
              Morgan James Cover of Dream On!
            </p>
            <p>
              <iframe
                width="420"
                height="236"
                src="//www.youtube.com/embed/Yq4KA0mUnC8"
                frameborder="0"
                allowfullscreen=""
              ></iframe>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>A battle of wills, my money is on the squirrel!</p>
            <p>
              <iframe
                width="420"
                height="236"
                src="//www.youtube.com/embed/s4YthEm5qIk"
                frameborder="0"
                allowfullscreen=""
              ></iframe>
            </p>
          </td>
          <td>
            <p>My Terrifying Rat Terrier defending her Terra Firma!</p>
            <p>
              <iframe
                width="420"
                height="236"
                src="//www.youtube.com/embed/t8uQsiHRWtc"
                frameborder="0"
                allowfullscreen=""
              ></iframe>
            </p>
          </td>
        </tr>
      </table>
    </div>
</template>

<script>
export default {}
</script>

<style></style>
