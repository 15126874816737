<template>
  <div id="primary">
    <article>
      <h1>Admonishing Admirals</h1>
      <div class="content">
        <p>
          <span
            >The great thing about Linux is it lets you do anything you want,
            the bad thing about Linux is it lets you do anything you want!
          </span>
        </p>
        <p>
          <span>
            On my first job, I killed a $4 million Navy project and had a very
            angry admiral admonish me! I was very confident of my command-line skills
            and I was asked to copy a file to a Linux workstation. The copy
            command in Linux copied the file where I told it to copy,
            unfortunately the path description was not detailed enough and
            started copying onto the drive over the workstation's OS files.
            Without an OS we were not able to continue with the project!</span
          >
        </p>
        <p>
          <img
            width="200"
            height="140"
            alt=""
            src="../../images/Bismark.png"
            align="right"
          />
        </p>
        <p>
          <span
            >On the next project they put me on, the first thing I did without
            being asked, was to write a backup program for our project files.
            The backup program became a very integral part of the project and
            evolved into our version control system.</span
          >
        </p>
        <p>
          <span
            >That was early in my career, I now know I could have removed the
            hard drive and recovered the files using the drive as an external
            drive instead of the OS drive. For some reason no one ask me what I
            thought. I still do not know why they ran a $4 million navy project
            without backup for the source files.</span
          >
        </p>
      </div>
    </article>
    <article>
      <div class="content">
        <!--           <div style="
                  border: none;
                  border-bottom: double windowtext 2.25pt;
                  padding: 0in 0in 1pt 0in;
                "
              > -->
        <br /><br /><br /><br />
        <h1>Pothole Pitfalls</h1>
        <p>
          <span
            >On the first day of my new job, I was focused on finding the best
            parking space when I swerved to miss the mother of all potholes. For
            the first week I forgot to avoid the pothole every morning as I
            hunted down my parking space. Most of the time I missed it, and like
            the other parking space hunters of the building I just became
            conditioned to miss it.</span
          >
        </p>
        <p>
          <img
            width="175"
            height="109"
            alt=""
            src="../../images/pothole.png"
            align="right"
          />
        </p>
        <p>
          <span
            >That pothole was not my problem anymore, but that was not good
            enough, what about other new hires, potential customers, lost souls
            looking for safe refuge. That pothole was still a menace. I thought
            that asphalt was still a government-controlled substance, but I was
            able to obtain some from Home Depot and I was able to patch it in
            about a half an hour.</span
          >
        </p>
        <p>
          <span
            >The next day, I went into my very busy employer&rsquo;s office to
            tell him what I had done. He could care less about talking about the
            annoying hole in his parking lot. But when I mentioned that is was
            fixed, he stopped, asked the person on the phone if he could call
            them back and then gave me his complete attention.
            &ldquo;What?&rdquo;, he asked. I repeated it was fixed and he turned
            around and looked out his window to see the hole was gone. He
            thanked me and that was it.</span
          >
        </p>
        <p>
          <span
            >A few days later I was called into my director&rsquo;s office to be
            told the owner appreciated what I had done and that he thought it
            showed a lot of initiative. I just saw a problem and fixed it.</span
          >
        </p>
      </div>
    </article>
  </div>
  <!-- <div
                id="i4c-draggable-container"
                style="position: fixed; z-index: 1499; width: 0px; height: 0px"
              ></div>
              <div id="i4c-dialogs-container"></div> -->
</template>

<script>
export default {}
</script>

<style></style>
