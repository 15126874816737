<template>
  <div align=" center ">
    <b-container>
      <h4>Application Features</h4>
      <br /><br />
      <ui class="list-unstyled" align=" left ">
        <li>00. Common Loader added</li>
        <li>01. Perfect Folder Structure</li>
        <li>02. Vue Bootstrap style implemented</li>
        <li>03. Router</li>
        <li>04. Vuex</li>
        <li>05. Ubuntu 20.10 NGINX cloud host</li>
        <li>06. REST API implemented</li>
        <li>07. CRUD Operations (Create, Read, Update, Delete)</li>
        <li>08. Vuelidate (Form Validation)</li>
        <li>09. Multiple filters and Sorting added</li>
        <li>10. Pagination added</li>
        <li>11. Navigation Menu</li>
        <li>12. Accordion UI</li>
        <li>13. Custom Utils functions (localstorage, ... etc)</li>
        <li>14. Modal Popup implemented</li>
      </ui>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'about'
}
</script>

<style>
</style>
